<template>
  <v-card flat class="ma-3">

    <InfosCandidat ref="infoscandidat"></InfosCandidat>
    <UpdateCandidat ref="updatecandidat" @candidatUpdated="fetchCandidats()"></UpdateCandidat>
    <DelCandidat ref="delcandidat" @candidatDeleted="fetchCandidats()"></DelCandidat>

    <v-card height="110" class="elevation-3 rounded-xl pa-2 mb-3" flat color="#FFFEFE">

      <v-card class="d-flex flex-row mb-3" flat>

        <!-- Barre de recherche -->
        <v-text-field v-model="search" clearable background-color="#f4f4f4" class="rounded-pill mr-2" dense hide-details
          single-line label="Rechercher" outlined rounded>

          <template v-slot:append>
            <v-card @click="fetchCandidats()" flat class="pa-1 rounded-circle pos-button" color="#1b6b9a">
              <v-icon color="white">mdi-magnify</v-icon>
            </v-card>
          </template>
        </v-text-field>


        <v-btn-toggle v-model="toggle" mandatory rounded dense class="rounded-pill" color="mc2i" @change="changeToggle()">
          <v-btn value="OPE">OPE</v-btn>
          <v-btn value="FCT">FCT</v-btn>
        </v-btn-toggle>

      </v-card>

      <v-card class="d-flex flex-row align-center" flat>
        <!-- <v-row class="mx-2">
          <v-menu v-for="name in ['Mois de signature', 'Année de signature', 'Mois d\'entrée',  'Année d\'entrée']" :close-on-content-click="false" :key="name" offset-y rounded
            dense>
            <template v-slot:activator="{ attrs, on }">
              <v-btn color="default" class="mx-2 my-2 custom-btn" v-bind="attrs" v-on="on">
                {{ name }}
                <v-chip v-if="name == 'Mois de signature'" color="primary" small class="pa-2 ma-2">{{
                  candidatsFilters[0].selected.length > 0 ?
                  candidatsFilters[0].selected.length : 0 }}</v-chip>

                  <v-chip v-if="name == 'Mois d\'entrée'" color="primary" small class="pa-2 ma-2">{{
                  candidatsFilters[2].selected.length > 0 ?
                  candidatsFilters[2].selected.length : 0 }}</v-chip>

                  <v-chip v-if="name == 'Année de signature'" color="primary" small class="pa-2 ma-2">{{
                  candidatsFilters[1].selected.length > 0 ?
                  candidatsFilters[1].selected.length : 0 }}</v-chip>

                  <v-chip v-if="name == 'Année d\'entrée'" color="primary" small class="pa-2 ma-2">{{
                  candidatsFilters[3].selected.length > 0 ?
                  candidatsFilters[3].selected.length : 0 }}</v-chip>
              </v-btn>
            </template>
            <v-list>
              <v-card max-height="300" class="overflow-y-auto ps-2 pe-0" flat>
                  <v-date-picker v-if="name == 'Mois de signature'" class="d-flex"
                  v-model="candidatsFilters[0].selected" no-title type="month" multiple locale="fr-FR" width="228"></v-date-picker>
              </v-card>

              <v-card max-height="300" class="overflow-y-auto ps-2 pe-0" flat>
                  <v-date-picker v-if="name == 'Mois d\'entrée'" class="d-flex"
                  v-model="candidatsFilters[2].selected" no-title type="month" multiple locale="fr-FR" width="228"></v-date-picker>
              </v-card>

              <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                v-if="name == 'Année de signature'" flat>
                <v-checkbox class="ma-0" v-for="anneSignature in customYears" v-model="candidatsFilters[1].selected"
                  :key="anneSignature" :value="anneSignature" :label="anneSignature"></v-checkbox>
              </v-card>

              <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                v-if="name == 'Année d\'entrée'" flat>
                <v-checkbox class="ma-0" v-for="anneEntre in customYears" v-model="candidatsFilters[3].selected"
                  :key="anneEntre" :value="anneEntre" :label="anneEntre"></v-checkbox>
              </v-card>
            </v-list>
          </v-menu>

        </v-row> -->

       
        <v-chip-group :key="key" v-model="selectedFilterChip" mandatory>
          <v-menu v-model="showChipMenu" :close-on-content-click="false" transition="fab-transition" rounded="xl"
            max-width="256" offset-y bottom>
            <template>
              <v-chip v-for="filter in activeFilters" :key="filter.text" class="rounded-pill">
              </v-chip>
            </template>
          </v-menu>
        </v-chip-group>

        <v-btn @click="exportData" :loading="exporting" :disabled="exporting" class="floating-export-btn" color="#0F9D58" v-if="userRoles.includes(1) || userRoles.includes(2) || userRoles.includes(3) || userRoles.includes(4) || userRoles.includes(6) || userRoles.includes(7) || userRoles.includes(8) || userRoles.includes(9) || userRoles.includes(10)" rounded small fab>
          <v-icon color="white">mdi-google-spreadsheet</v-icon>
        </v-btn>

      </v-card>
    </v-card>
    <v-data-table class="justify-center elevation-4 rounded-xl grey lighten-3" :items="candidats"
      :server-items-length="totalItems" :loading="loading" :server-search="true" :server-pagination="true"
      :items-per-page.sync="page_size" :page.sync="page" :server-items="fetchCandidats" :footer-props="{
        'itemsPerPageOptions': [250, 500, 750, 1000],
        'showCurrentPage': true,
        'show-first-last-page': true,
        'items-per-page-text': 'Rows per page :',
        'rows-per-page-text': 'Rows'
      }" no-data-text="Aucun candidat trouvé" no-results-text="Aucun candidat trouvé"
      loading-text="Chargment des candidats...">
      <template v-slot:header="header">
        <thead>
          <tr>
            <th v-for="header in headers" class="pa-2 text-center">
              {{ header.text }}
              <v-menu :close-on-content-click="false" rounded="xl" class="d-block" rigth offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">{{ shownFilterIcons(header.value) }}</v-icon>
                </template>
                <v-list>
                   <!-- Date signature -->
                  <v-date-picker v-if="header.value == candidatsFilters[4].value" :first-day-of-week="1" v-model="candidatsFilters[4].selected"
                    no-title range multiple locale="fr-FR" width="228" @change="handleDateRangeChange(4)"><v-chip variant="outlined" @click="clearDate(4)" class="mx-auto">Réinitialiser filtre</v-chip></v-date-picker>
                  
                  <!-- mois de signature -->
                  <v-date-picker v-if="header.value == candidatsFilters[0].value" class="d-flex"
                    v-model="candidatsFilters[0].selected" no-title type="month" multiple locale="fr-FR" width="228"
                    ><v-chip
                      variant="outlined" @click="clearDate(0)" class="mx-auto">Réinitialiser
                      filtre</v-chip></v-date-picker>

                  <!-- annee de signature -->
                    <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[1].value" flat>
                      <v-checkbox class="ma-0" v-for="anneSignature in customYears" v-model="candidatsFilters[1].selected"
                         :value="anneSignature" :label="anneSignature"></v-checkbox>
                    </v-card>

                  <!-- Date entree -->
                  <v-date-picker v-if="header.value == candidatsFilters[5].value" :first-day-of-week="1" v-model="candidatsFilters[5].selected"
                    no-title range multiple locale="fr-FR" width="228" @change="handleDateRangeChange(5)"><v-chip variant="outlined" @click="clearDate(5)" class="mx-auto">Réinitialiser filtre</v-chip></v-date-picker>

                  <!-- mois de entree -->
                  <v-date-picker v-if="header.value == candidatsFilters[2].value" class="d-flex"
                    v-model="candidatsFilters[2].selected" no-title type="month" multiple locale="fr-FR" width="228"
                    ><v-chip
                      variant="outlined" @click="clearDate(2)" class="mx-auto">Réinitialiser
                      filtre</v-chip></v-date-picker>

                  <!-- annee de entree -->
                    <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[3].value" flat>
                      <v-checkbox class="ma-0" v-for="anneEntree in customYears" v-model="candidatsFilters[3].selected"
                        :value="anneEntree" :label="anneEntree"></v-checkbox>
                    </v-card>


                  <!-- Date entree mc2i -->
                  <v-date-picker v-if="header.value == candidatsFilters[19].value" :first-day-of-week="1" v-model="candidatsFilters[19].selected"
                    no-title range multiple locale="fr-FR" width="228" @change="handleDateRangeChange(19)"><v-chip variant="outlined" @click="clearDate(19)" class="mx-auto">Réinitialiser filtre</v-chip></v-date-picker>

                  
                  
                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[6].value" flat>

                    <v-text-field class="ps-3 pe-3" v-model="profilSearch" label="Recherche un emploi" clearable @click:clear="profilSearch = null"></v-text-field>
                    <v-checkbox class="ma-0" v-for="profil in filteredProfil" v-model="candidatsFilters[6].selected"
                       :value="profil" :label="profil" :key="profil"></v-checkbox>
                  </v-card>

                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[7].value" flat>
                    <v-checkbox class="ma-0" v-for="sexe in champs.sexe" v-model="candidatsFilters[7].selected"
                      :value="sexe" :label="sexe"></v-checkbox>
                  </v-card>

                  <!-- <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[8].value" flat>
                    <v-checkbox class="ma-0" v-for="necole in champs.nom_ecole" v-model="candidatsFilters[8].selected"
                       :value="necole" :label="necole"></v-checkbox>
                  </v-card> -->


                  <div v-if="header.value == candidatsFilters[8].value">
                    <v-text-field class="ps-3 pe-3" v-model="EcoleSearch" label="Recherche Ecole  " clearable @click:clear="EcoleSearch = null"></v-text-field>

                    <div class="overflow-y-auto ">
                      <v-card max-height="300" flat>
                        <v-checkbox class="ps-3 pe-3 ma-0" v-for="ecole in filteredEcole" :key="ecole" v-model="candidatsFilters[8].selected" :value="ecole" :label="ecole"></v-checkbox>
                      </v-card>
                    </div>
                  </div>

                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[9].value" flat>
                    <v-checkbox class="ma-0" v-for="te in champs.types_ecole" v-model="candidatsFilters[9].selected"
                      :value="te" :label="te"></v-checkbox>
                  </v-card>

                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[10].value" flat>
                    <v-checkbox class="ma-0" v-for="rang_ecole in champs.rangs_ecole"
                      v-model="candidatsFilters[10].selected"  :value="rang_ecole"
                      :label="rang_ecole"></v-checkbox>
                  </v-card>

                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[11].value" flat>
                    <v-checkbox class="ma-0" v-for="entite in champs.label_entite" v-model="candidatsFilters[11].selected"
                       :value="entite" :label="entite"></v-checkbox>
                  </v-card>



                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[12].value" flat>
                    <v-checkbox class="ma-0" v-for="re in champs.poles" v-model="candidatsFilters[12].selected"
                      :value="re" :label="re"></v-checkbox>
                  </v-card>

                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[13].value" flat>
                    <v-checkbox class="ma-0" v-for="contrat in champs.label_contrat"
                      v-model="candidatsFilters[13].selected" :value="contrat"
                      :label="contrat"></v-checkbox>
                  </v-card>

                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[15].value" flat>
                    <v-checkbox class="ma-0" v-for="sl in champs.origine" v-model="candidatsFilters[15].selected"
                       :value="sl" :label="sl"></v-checkbox>
                  </v-card>
                  <div  v-if="header.value == candidatsFilters[18].value">
                    <v-text-field class="ps-3 pe-3" v-model="tacSearch" label="Recherche TAC" clearable @click:clear="tacSearch=null"></v-text-field>

                    <div class="overflow-y-auto ">
                      <v-card max-height="300" flat>
                        <v-checkbox class="ps-3 pe-3 ma-0" v-for="tac in filteredTAC" :key="tac" v-model="candidatsFilters[18].selected" :value="tac" :label="tac"></v-checkbox>
                      </v-card>
                    </div>
                  </div>
                  <!-- <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[18].value" flat>
                    <v-checkbox class="ma-0" v-for="tac in champs.trigrammes" v-model="candidatsFilters[18].selected"
                      :value="tac" :label="tac"></v-checkbox>
                  </v-card> -->
                  <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[14].value" flat>
                    <v-checkbox class="ma-0" v-for="or in champs.salaires" v-model="candidatsFilters[14].selected"
                      :value="or.toString()" :label="or.toString()"></v-checkbox>
                  </v-card>
                  <!-- <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[17].value" flat>
                    <v-checkbox v-for="el in ['Oui', 'Non']" v-model="candidatsFilters[17].selected"
                      :value="el == 'Oui' ? 1 : 0" :key="el" :label="el"></v-checkbox>
                  </v-card> -->
                </v-list>
              </v-menu>
            </th>
          </tr>
        </thead>
      </template>


      <!--Couleur du candidat-->
      <template v-slot:item="{ item }">
        <tr class="grey lighten-2">
          <td>{{ item.trigrammeTAC }}</td>
          <td>{{ dateEnToFr(item.date_signature) }}</td>
          <td>{{ item.date_signature ? new Date(item.date_signature).getMonth() + 1 : 'NA' }}</td>
          <td>{{ item.date_signature ? new Date(item.date_signature).getFullYear() : 'NA' }}</td>
          <td>{{ dateEnToFr(item.date_entree) }}</td>
          <td>{{ item.date_entree ? new Date(item.date_entree).getMonth() + 1 : 'NA' }}</td>
          <td>{{ item.date_entree ? new Date(item.date_entree).getFullYear() : 'NA' }}</td>
          <td>{{ item.date_entree_mc2i ? new Date(item.date_entree_mc2i).toLocaleDateString('fr-FR') : '' }}</td>
          <td>{{ item.matricule }}</td>
          <td>{{ item.profilFCT }}</td>
          <td>{{ item.nom }}</td>
          <td>{{ item.prenom }}</td>
          <td>{{ item.sexe }}</td>
          <td>{{ item.nom_ecole }}</td>
          <td>{{ item.type_ecole }}</td>
          <td>{{ item.rang_ecole }}</td>
          <td>{{ item.label_entite }}</td>
          <td>{{ item.pole }}</td>
          <td>{{ item.label_contrat }}</td>
          <!-- <td v-if="item.high_potential === 1"> OUI </td>
          <td v-else> NON </td> -->
          <!-- <td v-if="currentRole == 1">{{ item.salaire }}</td> -->
          <td v-if="userRoles.includes(1) || userRoles.includes(6) || userRoles.includes(7) || userRoles.includes(8) || userRoles.includes(9)">{{ item.salaire }}</td>
          <td v-else>
            <v-icon>mdi-link-lock</v-icon>
          </td>        
          <td>{{ calculeDateAnciennete(item.date_entree, item.experience) }}</td>  
          <td>{{ item.origine }}</td>


          <!--Bouton de gestion du candidat-->
          <td v-if="userRoles.includes(1) || userRoles.includes(2) || userRoles.includes(4) || userRoles.includes(5)">
            <v-menu rounded="xl" left offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list rounded dense>
                <v-list-item @click="$refs.infoscandidat.showInfos(item)">
                  <v-list-item-icon>
                    <v-icon class="pb-2">mdi-information</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Informations</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$refs.updatecandidat.openDialog(item.id, typeCandidat, item)">
                  <v-list-item-icon>
                    <v-icon class="pb-2">mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Modifier</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$refs.delcandidat.openDialog(item.id, typeCandidat)">
                  <v-list-item-icon>
                    <v-icon class="pb-2">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Supprimer</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
         
            <td v-else>
            <v-icon>mdi-link-lock</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import InfosCandidat from "../components/InfosCandidat";
import DelCandidat from "../components/form/DelCandidat";
import UpdateCandidat from "../components/form/UpdateCandidat";
import { embaucheFCTHeaders } from "../TabHeaders/embaucheFCTHeaders.js";
import { embauchesFCTFilters } from "../components/filters/embauchesFCTFilters.js";
import { getCandidats } from "../api/candidats";
import { rowColor, chipColor } from "../commonFunction/statusColors";
import { dateEnToFr, dateInText } from "../commonFunction/date";
import { getChamps } from "../api/champs";
import { customYears } from "../commonFunction/date";
import { exportToGSheet } from '../api/export'


export default {
  components: {
    InfosCandidat,
    DelCandidat,
    UpdateCandidat
  },
  data: () => ({
    typeCandidat: "fct",
    toggle: "FCT",
    exporting: false,
    item: {}, //Variable de stockage de l'item du tableau
    headers: [],
    candidatFCTHeaders: [], //Lien entre les noms de colonnes et les données
    search: "",
    selectedFilter: "",
    selectedFilterChip: "",
    candidatsFilters: [],
    candidats: [],
    filters: {},
    page_size: 250,
    loading: false,
    totalItems: 0,
    page: 1,
    perPage: 10,
    loading: false,
    showChipMenu: false,
    showListMenu: false,
    inactiveFilters: [],
    key: 0,
    tableKey: 0,
    customYears: [],
    tacSearch: null,
    profilSearch: null,
    EcoleSearch: null
  }),
  created() {
    this.headers = embaucheFCTHeaders; //Headers du tableau
    this.candidatsFilters = embauchesFCTFilters
    this.inactiveFilters = embauchesFCTFilters
  },
  async mounted() {
    // Initialisation du tableau de candidats
    this.fetchCandidats()
    this.$store.commit("SET_CHAMPS", await getChamps())
    this.customYears = customYears()
  },
  computed: {
    filteredTAC() { 
      if (this.tacSearch) { 
        const searchRegex = new RegExp(this.tacSearch, 'i'); 
        const filtered = this.champs.TAC.filter(tac => searchRegex.test(tac)); 
        return filtered; 
      } else { 
        return this.champs.TAC; 
      }
    },
    filteredProfil() { 
      if (this.profilSearch) { 
        const searchRegex = new RegExp(this.profilSearch, 'i'); 
        const filtered = this.champs.profilsFCT.filter(profil => searchRegex.test(profil)); 
        return filtered; 
      } else { 
        return this.champs.profilsFCT; 
      }
    },
    filteredEcole() { 
      if (this.EcoleSearch) { 
        const searchRegex = new RegExp(this.EcoleSearch, 'i'); 
        const filtered = this.champs.nom_ecole.filter(ecole => searchRegex.test(ecole)); 
        return filtered; 
      } else { 
        return this.champs.nom_ecole; 
      } 
    },
    shownFilterIcons() {
      return (header) => {
        const showIcon = this.showFilterIcon(header).length > 0;
        const showVariantIcon = this.showFilterIcon(header).length === 1 && !this.curentHeaderFilters(header).length > 0;
        return showVariantIcon ? 'mdi-filter-variant' : showIcon ? 'mdi-filter' : '';
      };
    },
    userRoles() {
      return this.$store.getters.currentRoles;
    },
    champs() {
      return this.$store.getters.champs
    },
    activeFilters() {
      if (!this.showChipMenu) {
        this.key += 1
      }
      return this.candidatsFilters.filter((f) => {
        this.filters = {
          type: "fct",
          statut: "Embauché",
          page: this.page,
          page_size: this.page_size,
          search: this.search,       
          mois_signature: this.candidatsFilters[0].selected.map(d => d.split("-")[1]),
          annee_signature: this.candidatsFilters[1].selected,
          mois_entree: this.candidatsFilters[2].selected.map(d => d.split("-")[1]),
          annee_entree: this.candidatsFilters[3].selected,
          date_signature: this.candidatsFilters[4].selected,
          date_entree: this.candidatsFilters[5].selected,
          profilFCT: this.candidatsFilters[6].selected,
          sexe: this.candidatsFilters[7].selected,
          nom_ecole: this.candidatsFilters[8].selected,
          type_ecole: this.candidatsFilters[9].selected,
          rang_ecole: this.candidatsFilters[10].selected,
          label_entite: this.candidatsFilters[11].selected,
          pole: this.candidatsFilters[12].selected,
          label_contrat: this.candidatsFilters[13].selected,
          salaire: this.candidatsFilters[14].selected,
          origine: this.candidatsFilters[15].selected,
          trigrammeTAC: this.candidatsFilters[18].selected,
          entree_mc2i: this.candidatsFilters[19].selected,

          // high_potential: this.candidatsFilters[17].selected,
        }
        return f.selected.length > 0
      })
    }
  },

  methods: {
    calculeDateAnciennete(dateEntree, experience) {
  const entryDate = new Date(dateEntree);

  const years = Math.floor(experience);
  const monthsDecimal = (experience - years) * 12;

  entryDate.setFullYear(entryDate.getFullYear() - years);

  entryDate.setMonth(entryDate.getMonth() - monthsDecimal);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
  };

  return formatDate(entryDate);
},
    showFilterIcon(header) {
      return this.candidatsFilters.filter(elt => elt.value === header);
    },
    curentHeaderFilters(header) {
      return this.candidatsFilters.filter(elt => elt.value == header && elt.selected.length > 0)
    },

    async exportData() {
      try {
        this.exporting = true; 
        const email = this.$store.getters.user.email;
        const result = await exportToGSheet(email, this.filters)

        window.open(result.SheetURL, "_blank");
        
        console.log('Exportation terminée!');
      } catch (error) {
        console.error('Erreur lors de l\'exportation vers Google sheet', error);
      } finally {
        this.exporting = false; 
      }
    },

    handleDateRangeChange(fieldIndex) {
      const selectedDates = this.candidatsFilters[fieldIndex].selected;

      const startDate = new Date(selectedDates[0]);
      const endDate = new Date(selectedDates[selectedDates.length - 1]);
      const allDates = this.getDatesBetween(startDate, endDate);

      this.candidatsFilters[fieldIndex].selected = allDates;

      this.fetchCandidats();
    },

    clearDate(fieldIndex){
      const selectedDates = this.candidatsFilters[fieldIndex].selected;
      this.candidatsFilters[fieldIndex].selected = selectedDates.filter((item) => item == null);
    },

    // Fonction pour générer toutes les dates entre deux dates
    getDatesBetween(startDate, endDate) {
      console.log({startDate, endDate})
      const dates = [];
      for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
          dates.push(currentDate.toISOString().split('T')[0]); // Format des dates ISO (YYYY-MM-DD)
      }
      return dates;
    },
    async fetchCandidats() {
      this.loading = true;

      // Sauvegarder la page actuelle avant de fetch les candidats
      const currentPage = this.page;

      await getCandidats(this.filters).then((response) => {
        this.candidats = response.items;
        this.totalItems = response.total;

        // Vérifier si la page actuelle est toujours valide après le filtrage
        if (currentPage > Math.ceil(this.totalItems / this.page_size)) {
          // Si la page actuelle n'est plus valide, revenir à la première page
          this.page = 1;
        }

        this.loading = false;
      });
    },
    removeFilter(filter) {
      for (let i = 0; i < this.candidatsFilters.length; i++) {
        if (this.candidatsFilters[i].value == filter) {
          this.candidatsFilters[i].selected = []
        }
      }
    },
    updateFilters() {
      //console.log("updateFilters triggered")
      this.inactiveFilters = this.candidatsFilters.filter((f) => {
        return f.selected.length == 0
      })
    },
    changeToggle() {
      this.$router.push('/embauches/ope')
    },
    dateEnToFr,
    dateInText,
    rowColor,
    chipColor,
  },
  watch: {
    showListMenu(newValue) {
      // Actutaliser les filtres des candidats quand on ferme le menu déroulant
      if (newValue == false) {
        this.updateFilters()
      }
    },
    showChipMenu(newValue) {
      // Actutaliser les filtres des candidats quand on ferme une chip
      if (newValue == false) {
        this.updateFilters()
      }
    },
    filters() {
      this.fetchCandidats()
    }
  }
};
</script>

<style scoped>
td {
  border: none;
  text-align: center;
}

ul {
  list-style-type: none;
  text-align: center;
}


.floating-export-btn {
  position: fixed;
  bottom: 15px;
  left: 70px;
  z-index: 1;
}

.pos-button {
  position: relative;
  top: -4px;
  left: 19px;
}
</style>