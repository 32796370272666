export const embauchesFCTFilters = [
  {
    text: "Mois de signature",
    value: "mois_signature",
    selected: []
  },
  {
    text: "Année de signature",
    value: "annee_signature",
    selected: []
  },
  {
    text: "Mois d'entrée",
    value: "mois_entree",
    selected: []
  },
  {
    text: "Année d'entrée",
    value: "annee_entree",
    selected: []
  },
  {
    text: "Date de signature",
    value: "date_signature",
    selected: []
  },
  {
    text: "Date d'entrée",
    value: "date_entree",
    selected: []
  },
  {
    text: "Profil",
    value: "profilFCT",
    selected: []
  },
  {
    text: "Sexe",
    value: "sexe",
    selected: []
  },
  {
    text: "Ecole",
    value: "nom_ecole",
    selected: []
  },
  {
    text: "Type d'école",
    value: "type_ecole",
    selected: []
  },
  {
    text: "Rang école",
    value: "rang_ecole",
    selected: []
  },
  {
    text: "Entité",
    value: "label_entite",
    selected: []
  },
  {
    text: "Pôle",
    value: "pole",
    selected: []
  },
  {
    text: "Type de contrat",
    value: "label_contrat",
    selected: []
  },
  {
    text: "Salaire",
    value: "salaires",
    selected: []
  },
  {
    text: "Origine",
    value: "origine",
    selected: []
  },
  {
    text: "Precision Origine",
    value: "precision_origine",
    selected: []
  },
  {
    text: "HP",
    value: "high_potential",
    selected: []
  },
  {
    text: "TAC",
    value: "trigramme",
    selected: []
  },
  {
    text: "Date d'entrée mc2i",
    value: "entree_mc2i",
    selected: []
  }

  // {
  //   text: "Matricule",
  //   value: "matricule",
  //   selected: []
  // },
];
